import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex bd-highlight align-center flex-wrap"},[_vm._v(" Retenção da carteira ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataTable,"loading-text":"Carregando dados...","loading":_vm.loading,"disable-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.reference === _vm.loadingCircle ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.viewSales(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiExpandAllOutline)+" ")]):_vm._e(),(item.reference === _vm.loadingCircle ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}}],null,true)})],1),_c(VDialog,{attrs:{"width":"1600"},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('modal-retention-home',{key:_vm.reference,attrs:{"vendor-name":_vm.vendor_name,"reference":_vm.reference},on:{"close":function($event){_vm.openModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }