<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        Retenção da carteira
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.reference === loadingCircle ? false : true"
            medium
            class="me-2"
            @click="viewSales(item)"
          >
            {{ icons.mdiExpandAllOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.reference === loadingCircle ? true : false"
            indeterminate
            :size="25"
            color="gray"
          ></v-progress-circular>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="openModal"
      width="1600"
    >
      <modal-retention-home
        :key="reference"
        :vendor-name="vendor_name"
        :reference="reference"
        @close="openModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import ModalRetentionHome from '@/views/pages/administrator/retention/ModalRetentioHome.vue'
import { mdiExpandAllOutline } from '@mdi/js'

export default {
  components: {
    ModalRetentionHome,
  },
  data() {
    return {
      openModal: false,
      vendor_name: '',
      reference: '',
      loadingCircle: '',
      icons: {
        mdiExpandAllOutline,
      },
      loading: false,
      dataTable: [],
      headers: [
        { text: 'REFERENCIA', value: 'reference' },
        { text: 'VENDA', value: 'venda', align: 'center' },
        { text: 'PARC 01', value: 'parcela01', align: 'center' },
        { text: 'PARC 02', value: 'parcela02', align: 'center' },
        { text: 'PARC 03', value: 'parcela03', align: 'center' },
        { text: 'PARC 04', value: 'parcela04', align: 'center' },
        { text: 'PARC 05', value: 'parcela05', align: 'center' },
        { text: '% PARC 2', value: 'retencao02', align: 'center' },
        { text: '% PARC 5', value: 'retencao05', align: 'center' },
        { text: 'RETENÇÃO ( 1º a 5º )', value: 'desempenho', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },
      ],
    }
  },

  async created() {
    await this.getItemsTable()
  },

  methods: {
    getItemsTable() {
      this.loading = true
      const body = {
        vendor_name: localStorage.getItem('username'),
      }
      axiosIns.post('/api/v1/integration/retention/portfolio_retention/', body)
        .then(res => {
          this.dataTable = res.data.data
        })

      this.loading = false
    },

    async viewSales(item) {
      this.reference = item.reference
      this.vendor_name = item.nomevendedor

      this.openModal = true
    },
  },
}
</script>
